import BaseCrudService from "./baseCrud.service";

export default class VolunteeringAttributeOfferService extends BaseCrudService {

    constructor() {
        super('VolunteeringAttributeOffer');
    }

    findById(id) {
        return this.getRequest(`FindById/${id}`);
    }

    findByVolunteeringId(volunteeringId) {
        return this.getRequest(`FindByVolunteeringId/${volunteeringId}`);
    }

    listAll() {
        return this.getRequest('ListAll');
    }

    save(volunteeringAttributeOffer) {
        return this.postRequest('Save', volunteeringAttributeOffer);
    }

    delete(id) {
        return this.deleteRequest(`Delete/${id}`);
    }

    deleteVolunteeringPontualType(id) {
        return this.deleteRequest(`DeleteVolunteeringPontualType/${id}`);
    }

    deleteVolunteeringRecurrentType(id) {
        return this.deleteRequest(`DeleteVolunteeringRecurrentType/${id}`);
    }
    listVolunteeringAttributeOfferByCampaign(){
        return this.getRequest(`ListVolunteeringAttributeOfferByCampaign`);
    }
}